/* You can add global styles to this file, and also import other style files */

html, body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

